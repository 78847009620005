import React from "react";
import { GradientLeft, GradientRight } from "./components/background/Background";

export default function App() {
  return (
    <div className="relative flex flex-col" id="app-container">
      <div className="relative container mx-auto max-w-7xl z-10 px-6 min-h-[calc(100vh_-_64px_-_108px)] mb-12 flex-grow">
        <h1 className="biggerDisplay select-none text-[10vw] leading-[9vw] mt-[5rem]">
          MALLORY<br/>SCOTTON
        </h1>
      </div>
      <GradientLeft />
      <GradientRight />
    </div>
  );
}
